<template>
  <section class="mainBanner">
    <slot></slot>
    <picture class="mainBanner-image">
      <source :srcset="bannerData.image.desktop" media="(min-width: 768px)" :alt="bannerData.title" />
      <img :src="bannerData.image.mobile" :alt="bannerData.title" />
    </picture>
    <div class="mainBanner-container main">
      <div class="mainBanner-content">
        <h2 class="mainBanner-title">{{ bannerData.title }}</h2>
        <p class="mainBanner-description">{{ bannerData.desc }}</p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: { bannerData: Object },
};
</script>
<style lang="scss">
.mainBanner {
  height: 100vh;
  max-height: 812px;
  padding: 300px 20px 58px 20px;
  font-family: $sec_font;
  position: relative;
  background: linear-gradient(180deg, rgba(68, 68, 68, 0) 53.65%, #000000 100%);
  &-container {
    color: white;
    height: 100%;
  }
  &-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 52%;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }
  &-title {
    font-size: 36px;
    margin-bottom: 10px;
    line-height: 48px;
    animation: 1s ease-out 0s 1 animationTitle;
  }
  @keyframes animationTitle {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  &-description {
    font-family: Averta;
    font-size: 17px;
    line-height: 17px;
  }

  @media (min-width: $tablet_width) {
    max-height: 1366px;
    &-content {
      max-width: 437px;
    }
    &-title {
      margin-bottom: 20px;
    }
  }
  @media (min-width: $desktop_width) {
    padding-bottom: 100px;
    &-content {
      max-width: 701px;
    }

    &-title {
      font-size: 56px;
      margin-bottom: 10px;
      line-height: 70px;
      letter-spacing: 0.01em;
    }
    &-description {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
