<template>
  <div class="contactForm">
    <form id="contactForm" class="contactForm-form form" @submit.prevent="sendForm" novalidate>
      <label needed for="name">
        <span>{{ form.name.label }}</span>
        <input name="name" @keyup="validateInput" type="text" data-type="name" :placeholder="form.name.placeholder" />
      </label>
      <label needed for="email">
        <span>{{ form.email.label }}</span>
        <input name="email" @keyup="validateInput" type="email" :placeholder="form.email.placeholder" />
      </label>
      <label needed for="subject">
        <span>{{ form.subject.label }}</span>
        <input name="subject" @keyup="validateInput" type="text" data-type="any" :placeholder="form.subject.placeholder" />
      </label>
      <label for="message">
        <span>{{ form.message.label }}</span>
        <textarea name="message" :placeholder="form.message.placeholder" />
      </label>
      <label manual needed class="contactForm-checkbox" for="politics">
        <div checkbox>
          <input type="checkbox" @change="validateInput" id="politics" name="politics" :placeholder="form.politics.placeholder" />
          <label for="politics"></label>
        </div>
        <span>
          <a>{{ form.politics.label }} {{ form.politics.link }}</a>
        </span>
      </label>
      <button type="submit">{{ form.button }}</button>
    </form>
  </div>
</template>
<script>
import alerts from "../../assets/utils/swalAlerts";
export default {
  methods: {
    async sendForm({ target }) {
      const isValid = this.$validateOG.validateForm("#contactForm");
      if (isValid) {
        const { isConfirmed } = await alerts.dataSendConfirm();
        if (isConfirmed) {
          alerts.dataSending();
          const formData = new FormData(target);
          const { data } = await this.$api.post(`${process.env.VUE_APP_API_HOST}/jobs/contact`, formData);
          data.success ? alerts.dataSendSuccess() : alerts.dataSendError();
          target.reset();
        }
      }
    },
    validateInput({ srcElement }) {
      const type = srcElement.type === "text" ? srcElement.dataset.type : srcElement.type;
      this.$validateOG[type](srcElement);
    },
  },
  computed: {
    form() {
      return this.$locale.object["contact-form"];
    },
  },
};
</script>
<style lang="scss">
.contactForm {
  max-width: 455px;
  padding: 0 18px;
  position: absolute;
  top: 110px;
  left: 0;
  right: 0;
  margin: 0 auto;
  &-form {
    padding: 24px;
    background: #e2e2e2;
    opacity: 0.9;
    box-shadow: 6px 6px 8px rgba(102, 102, 102, 0.3), inset 0px 0px 8px #e2e2e2;
    border-radius: $mradius;
  }
  &-checkbox {
    font-size: 10px;
  }
  button[type="submit"] {
    display: block;
    margin-left: auto;
    margin-top: 10px;
    border: 1px solid transparent;
    border-radius: 14px;
    padding: 4px 28px;
    &:hover {
      border: 1px solid $shark;
      background: $mercury;
      color: $shark;
      box-shadow: 4px 4px 4px rgba(68, 68, 68, 0.3);
    }
  }
  textarea {
    height: 100px;
  }
  @media (min-width: $tablet_width) {
    &-checkbox {
      font-size: 12px;
    }
  }
  @media (min-width: $tv_width) {
    margin: 0 12% 0 auto;
    top: calc(50% - 235px);
    &-form {
      width: 500px;
    }
    &-checkbox {
      display: block;
      margin: 16px 0;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    textarea {
      height: 134px;
    }
  }
}
</style>
