<template>
  <main class="contact-container" v-if="this.$locale['object']['contact-main-banner']">
    <GlobalMainBanner class="contact-banner" :bannerData="$locale['object']['contact-main-banner']">
      <ContactForm />
    </GlobalMainBanner>
  </main>
  <Spinner v-else />
</template>

<script>
import GlobalMainBanner from "../global/GlobalMainBanner.vue";
import ContactForm from "./ContactForm.vue";

export default {
  components: { GlobalMainBanner, ContactForm },
  async beforeMount() {
    if (!this.$locale["object"]["contact-main-banner"]) await this.$store.dispatch("updateLocal");
  },
};
</script>

<style lang="scss">
.contact-banner {
  min-height: 795px;
  .mainBanner-content {
    max-width: 543px;
  }
  @media (min-width: $desktop_width) {
    min-height: 91vh;
  }
  @media (min-width: $tv_width) {
    max-height: 730px;
  }
}
</style>
