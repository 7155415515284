import Swal from "sweetalert2";
import store from "../../store";

export const alerts = {
  close: function() {
    Swal.close();
  },
  dataSendConfirm: function({ title, message, buttonTextYes, buttonTextNo } = {}) {
    const alert = store.state.locale.object["alert-question"];
    return Swal.fire({
      title: title || alert.title,
      text: message || alert.message,
      showCancelButton: true,
      confirmButtonText: buttonTextYes || alert.button.accept,
      cancelButtonText: buttonTextNo || alert.button.cancel,
    });
  },

  dataSending: function({ title, message } = {}) {
    const alert = store.state.locale.object["alert-loading"];
    Swal.fire({
      title: title || alert.title,
      text: message || alert.message,
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  },

  dataSendError: function({ title, message, buttonText } = {}) {
    const alert = store.state.locale.object["alert-error"];
    Swal.close();
    return Swal.fire({
      title: title || alert.title,
      html: message || alert.message,
      icon: "error",
      timerProgressBar: true,
    });
  },

  dataSendSuccess: function({ title, message, buttonText } = {}) {
    const alert = store.state.locale.object["alert-success"];
    Swal.close();
    return Swal.fire({
      title: title || alert.title,
      text: message || alert.message,
      icon: "success",
      confirmButtonText: buttonText || alert.button,
      timerProgressBar: true,
    });
  },
  dataInfo: function({ title, message, buttonText } = {}) {
    Swal.close();
    return Swal.fire({
      title: title || "",
      text: message || "",
      icon: "info",
      confirmButtonText: buttonText || "Aceptar",
      timerProgressBar: true,
    });
  },
};

export default alerts;
